<template>
  <div>
    <div v-if="barNotifs">
      <div class="notification-bar"
           :class="barNotifs.class_name"
           @click="readAdminBarNotif(barNotifs)">
        {{ barNotifs.message }}
        <i class="far fa-times-circle close-icon"></i>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  export default {
    name  : 'NotificationBar',
    props : {
      message : String,
    },
    data() {
      return {
        barNotifs : this.$store.state.notification.adminBarNotifs.data[0],
      }
    },
    watch : {
      '$store.state.notification.adminBarNotifs'(value) {
        this.barNotifs = value.data[0];
        if (value.data[0].notification_type_id === 7)
          this.barNotifs.message = this.message;
        
      },
    },
    methods : {
      ...mapActions({
        'getAdminBarNotifs' : 'notification/getAdminBarNotifs',
      }),

      /**
       * Read Notification
       */
      readAdminBarNotif(barNotif) {
        if (barNotif.class_name === 'unread') {
          this.$http.post('api/notification/admin/read', {
            adminNotifId : barNotif.admin_notif_id,
          }).then(() => {
            // update admin bar notifs list
            this.getAdminBarNotifs();
          });
        }

        // redirect
        if (this.$route.path != '/admin')
          this.$router.push({ path : barNotif.website_redirect_url });
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/notification/notification-bar";
</style>
